import React from "react";
import { Link, graphql } from "gatsby";
import { circleShape, ovalShape, wavesShape } from "../utils/shapes";
import Seo from "../components/shared/Seo";
import * as styles from "./styles/NotFoundPage.module.scss";

const NotFoundPage = ({ data }) => {
	const { data: pageData } = data.allPrismicNotFound.edges[0].node;

	return (
		<>
			<Seo
				title={pageData.page_title.text}
				description={pageData.page_description.text}
			/>

			<div className={styles.notFoundPage}>
				{circleShape}
				{wavesShape}
				{ovalShape}

				{pageData.show_404 && <h2>404</h2>}

				<h1>{pageData.page_title.text}</h1>

				<h3>{pageData.page_description.text}</h3>

				{pageData.show_home_btn && (
					<Link to="/" className="base-cta">
						Volver al inicio
					</Link>
				)}
			</div>
		</>
	);
};

export const query = graphql`
	{
		allPrismicNotFound {
			edges {
				node {
					data {
						page_title {
							text
						}
						page_description {
							text
						}
						show_404
						show_home_btn
					}
				}
			}
		}
	}
`;

export default NotFoundPage;
